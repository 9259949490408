<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>设置</el-breadcrumb-item>
        <el-breadcrumb-item>修改密码</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>

    <el-col :span="24" class="warp-main">
      <el-form ref="form" :model="form" :rules="editFormRules" label-width="120px">
        <el-form-item label="原密码" prop="oldPwd">
          <el-input v-model="form.oldPwd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input v-model="form.newPwd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmPwd">
          <el-input v-model="form.confirmPwd" type="password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
  import {reqUpdateAdminUserPwd} from '../../../api/system-api';

  export default{
    data(){
      var checkPwd = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请再次输入新密码'));
        }
        var newPwd = (!this.form.newPwd || this.form.newPwd == '') ? null : this.form.newPwd;
        var confirmPwd = (!this.form.confirmPwd || this.form.confirmPwd == '') ? null : this.form.confirmPwd;
        if(newPwd != null && newPwd !== confirmPwd) {
          callback(new Error('两次密码不一致！'));
        } else {
          callback();
        }
      };
      return {
        token:'',
        editFormRules: {
          oldPwd: [
            {required: true, message: '请输入新密码', trigger: 'blur'},
          ],
          newPwd: [
            {required: true, message: '请输入新密码', trigger: 'blur'},
            {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/, message:'密码由字母和数字组成6到16位',trigger: 'blur'},
          ],
          confirmPwd: [
            {required: true,validator: checkPwd, trigger: 'blur'},
          ],
        },
        form: {
          userId:0,
          oldPwd: '',
          newPwd: '',
          confirmPwd: ''
        }
      }
    },
    methods:{
      onSubmit() {
        let that=this;
        this.$refs.form.validate((valid) => {
          if (valid) {
            reqUpdateAdminUserPwd({token:this.token,oldPwd:this.form.oldPwd,newPwd:this.form.newPwd,confirmPwd:this.form.confirmPwd}).then((res) => {
              if(res.data.code===7){
                this.$message({
                  message: "原始密码输入不正确",
                  type: 'warning'
                });
                this.form.oldPwd='';
              }else if(res.data.data===5){
                this.$message({
                  message:"密码修改成功，请重新登录",
                  type: 'success'
                });
                that.logout();
              }else if(res.data.code===2){
                this.$message({
                  message:"重置的密码不能和原始密码一样",
                  type: 'warning'
                });
                this.form.newPwd='';
                this.form.confirmPwd='';
              }else {
                this.$message({
                  message: "密码修改失败",
                  type: 'warning'
                });
                this.form.oldPwd='';
                this.form.newPwd='';
                this.form.confirmPwd='';
              }
            });
          }
        });
      },
      logout(){
        var _this = this;
        sessionStorage.removeItem('Authorization');
        sessionStorage.removeItem('');
        _this.$router.push('/');
      },
    },
    mounted() {
      var token = sessionStorage.getItem('Authorization');
      if (token) {
       this.token=token;
      }
    }
  }
</script>
